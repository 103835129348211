import BaseController from 'controllers/base';
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

class Controller extends BaseController {
  call() {
    super.call();
    alertIfNotSave(this);
    sectorSortable(this);
  }
}

function alertIfNotSave(root) {
  let formUnsaved = false;
  root.root.find('form.sectors-form input').each((index, element) => {
    if (element.type === 'submit') {
      $(element).on('click', () => {
        formUnsaved = false;
      });
    } else {
      $(element).on('change', () => {
        formUnsaved = true;
        root.root.find('form.sectors-form input:submit').attr('value', 'Save!');
      });
    }
  });
  // eslint-disable-next-line consistent-return
  function unloadPage() {
    if (formUnsaved) {
      return 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';
    }
  }
  window.onbeforeunload = unloadPage;
}

const sectorSortable = function (root) {
  root.root.find('.sector-list').each((idx, elem) => {
    const sortablelist = new Sortable(elem, {
      group: 'sectors-lists',
      handle: '.drag-handle',
      animation: 150,
      ghostClass: 'temp-item',
      onEnd(evt) {
        updateSectorListValues(root);
      },
    });
  });
};

const updateSectorListValues = function (root) {
  // update unpublished list
  root.root.find('#unpublished-list').find('li.sector-item').each((itemIdx, itemElem) => {
    $(itemElem).find('input.sector-input-order').val(itemIdx);
    $(itemElem).find('input.sector-input-status').val('unpublished');
    $(itemElem).removeClass('published').addClass('unpublished border-danger');
  });
  // update published list
  root.root.find('.sector-list.published').each((idx, elem) => {
    const $elem = $(elem);
    const sector = $elem.attr('sector');
    $elem.find('li.sector-item').each((itemIdx, itemElem) => {
      $(itemElem).find('input.sector-input-order').val(itemIdx);
      $(itemElem).find('input.sector-input-type').val(sector);
      $(itemElem).find('input.sector-input-status').val('published');
      $(itemElem).removeClass('unpublished border-danger').addClass('published');
    });
  });
};

// const ajaxPostForm = function(){
//   const _this = this
//   const $form = _this.root.find("form.sectors-form")
//   $.ajax({
//     method: $form.attr("method"),
//     data: new FormData($form[0]),
//     url: $form.attr("action"),
//     contentType: false,
//     processData: false,
//     dataType: "json",
//     success(response) {
//       _this.emit("SECTORS_UPDATED", response)
//     },
//     error(error) {
//       _this.emit("SECTORS_UPDATE_ERROR", error)
//       swal.fire({ type: "error", title: "Sectors Update Failed!", text: error })
//     },
//   })
// }

export default Controller;
