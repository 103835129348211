export default function loadCkEditor(root) {
  root.on('FORM_RENERED', () => {
    root.root.find('.html_editor').each((index, element) => {
      const id = $(element).attr('id');
      const editor = window.CKEDITOR.replace(id, {
        height: 300,
        filebrowserImageBrowseUrl: '/backend/cms/attachments',
        allowedContent: true,
        enterMode: window.CKEDITOR.ENTER_BR,
        toolbar: [
          { items: ['Maximize'] },
          { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Source'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
          { name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
          { name: 'insert', items: ['Image', 'Video', 'Table', 'HorizontalRule', 'SpecialChar'] },
          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
          { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
          { name: 'colors', items: ['TextColor', 'BGColor'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat'] },
        ],
        contentsCss: '/fallback/ckeditor/fonts/font_faces.css',
        font_names: 'Walkway Bold/walkway_boldregular; Walkway Black/walkway_blackregular; Lucky Turns/lucky_turns; Arial/Arial, Helvetica, sans-serif;Comic Sans MS/Comic Sans MS, cursive;Courier New/Courier New, Courier, monospace;Georgia/Georgia, serif;Lucida Sans Unicode/Lucida Sans Unicode, Lucida Grande, sans-serif;Tahoma/Tahoma, Geneva, sans-serif;Times New Roman/Times New Roman, Times, serif;Trebuchet MS/Trebuchet MS, Helvetica, sans-serif;Verdana/Verdana, Geneva, sans-serif',
      });
      editor.on('change', () => {
        root.emit('FORM_INPUT_UPDAED');
        editor.updateElement();
      });
    });
  });
}
