// import React      from 'react';
// import ReactDOM   from 'react-dom';

import BaseController from "controllers/base"

class Controller extends BaseController {
  call() {
    super.call();

    console.log("Namepsace Base Controller")

  }
};

export default Controller;
