
import BaseController from "controllers/base"
// import CodeFlask from 'codeflask'

class Controller extends BaseController {
  call() {
    super.call();

  }
};

// const bindFlaskEditor = function() {
//   const $codeTextArea = this.root.find("#html-content")
//   const flask = new CodeFlask('#flask-editor', { language: 'html', lineNumbers: true });
//   flask.updateCode($codeTextArea.val())
//   flask.onUpdate(code => {
//     console.log(code)
//     $codeTextArea.val(code)
//   })
// }

export default Controller;
