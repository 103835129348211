import $ from 'jquery';
// eslint-disable-next-line import/extensions
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

function updateSortableListValues(root, lists) {
  root.root.find(lists).each((idx, list) => {
    const $list = $(list);
    $list.find('li').each((itemIdx, itemElem) => {
      $(itemElem).find('input.list-item-input-order').val(itemIdx);
    });
  });
}

export default function bindSortableOrderLists(root, list) {
  const sortableList = list || '.sortable-order-list';
  root.on('FORM_RENERED', () => {
    root.root.find(sortableList).each((idx, elem) => {
      // eslint-disable-next-line no-unused-vars
      const sortable = new Sortable(elem, {
        handle: '.drag-handle',
        animation: 150,
        ghostClass: 'temp-item',
        onEnd() {
          updateSortableListValues(root, sortableList);
        },
      });
    });
  });
}
