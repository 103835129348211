import rememberOpenedType from 'helpers/remberActiveTab';
import { disableButton, enableButton } from 'helpers/buttons';

export default function bindAttachments(root) {
  let $mainBody = root.root.find("[data-cms='image-attachments']");
  let $input;

  const loadMainBody = () => {
    $.ajax({
      method: 'get',
      dataType: 'json',
      url: $mainBody.attr('data-src'),
      success(response) {
        $mainBody.html(response.html);
        root.emit('ATTACHMENTS_BODY_LOADED', response);
      },
      error(error) {
        root.emit('ATTACHMENTS_BODY_LOAD_FAILED', error);
      },
    });
  };

  const loadSingleTypeTab = (typeId) => {
    $.ajax({
      method: 'get',
      dataType: 'json',
      url: $mainBody.find(`.attachment-type-tab[type-id='${typeId}']`).attr('data-src'),
      success(response) {
        $mainBody.find(`.attachment-type-tab[type-id='${typeId}']`).html(response.html);
        root.emit('ATTACHMENTS_BODY_LOADED', response);
      },
      error(error) {
        root.emit('ATTACHMENT_TYPE_TAB_RELOAD_ERROR', error);
      },
    });
  };

  // ======== actions ========
  const bindNewTypeCreateForm = () => {
    const $form = $mainBody.find('form#new-type-form');
    const $submitBtn = $form.find("[type='submit']");
    $form.on('submit', (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();
      disableButton($submitBtn);
      $.ajax({
        method: $form.attr('method'),
        data: new FormData(event.currentTarget),
        url: $form.attr('action'),
        contentType: false,
        processData: false,
        dataType: 'json',
        success(response) {
          root.emit('ATTACHMENT_TYPE_CREATED', response);
        },
        error(error) {
          root.emit('ATTACHMENT_TYPE_CREAT_ERROR', error);
        },
        complete() {
          $form.find("input[type='text']").val('');
          enableButton($submitBtn);
        },
      });
    });
  };

  const bindRemoveTypeButton = () => {
    $mainBody.find('a.type-remove').on('click', (e) => {
      const $button = $(e.currentTarget);
      disableButton($button);
      $.ajax({
        method: $button.attr('data-method'),
        url: $button.attr('href'),
        dataType: 'json',
        success(response) {
          root.emit('ATTACHMENT_TYPE_REMOVED', response);
        },
        error(error) {
          root.emit('ATTACHMENT_TYPE_REMOVE_ERROR', error);
        },
        complete() {
          enableButton($button);
        },
      });
      return false;
    });
  };

  const bindNewImageButton = () => {
    const checkUploadQueueCompleted = (objArray, typeId) => {
      objArray.forEach((obj) => {
        if (!obj.uploadStatus) {
          return false;
        }
      });
      root.emit('ATTACHMENT_UPLOADED', { typeId });
      return true;
    };

    $mainBody.find('.upload-btn').on('click', (event) => {
      const typeId = $(event.currentTarget).attr('type_id');
      $(`#file_upload-${typeId}`).trigger('click');
    });
    $('.file_upload').on('change', (event) => {
      const $fileInput = $(event.currentTarget);
      const $form = $fileInput.closest('form');
      const typeId = $fileInput.attr('type_id');
      const fileInputKey = $fileInput.attr('name');
      const singleFileInputKey = fileInputKey.replace('[]', '');
      const selectedFiles = [];
      const uploadQueue = [...$fileInput[0].files];
      $fileInput.val('');
      uploadQueue.forEach((file, idx) => {
        const data = new FormData($form[0]);
        const $wrapperEl = $('.img-wrapper').first().clone().empty()
          .append($('<div class="img-container p-0"></div>'));
        selectedFiles.push({
          file,
          element: $wrapperEl,
          uploadStatus: false,
        });
        const reader = new FileReader();
        reader.onload = (e) => {
          const $newEl = $('<img/>').attr('src', e.target.result).addClass('img-fluid');
          const $statusOverlay = $('<div class="spinner-overlay"><div class="spinner-border text-light" role="status"><span class="sr-only">Loading...</span></div><span class="ml-2">Uploading...</span></div>');
          $wrapperEl.children('.img-container').append($newEl).append($statusOverlay);
          $wrapperEl.attr('idex', idx).attr('type_id', typeId);
          $wrapperEl.insertAfter(`#new-add-btn-${typeId}`);
          data.delete(fileInputKey);
          data.set(singleFileInputKey, file);
          $.ajax({
            method: $form.attr('method'),
            data,
            url: $form.attr('action'),
            contentType: false,
            processData: false,
            dataType: 'json',
            success() {
              $statusOverlay.hide();
              selectedFiles[idx].uploadStatus = true;
              checkUploadQueueCompleted(selectedFiles, typeId);
            },
            error(error) {
              root.emit('ATTACHMENT_UPLOAD_ERROR', error);
            },
          });
        };
        reader.readAsDataURL(file);
      });
    });
  };

  // needed close button only close manage window
  const bindCloseManageModal = () => {
    $mainBody.find('.att-manage-close').on('click', (e) => {
      $(e.target).closest('.att-manage-modal').modal('hide');
      return false;
    });
  };

  const bindAttachmentDelete = () => {
    $mainBody.find('.delete-attachment').on('click', (e) => {
      const $deleteButton = $(e.currentTarget);
      disableButton($deleteButton);
      $.ajax({
        method: $deleteButton.attr('data-method'),
        url: $deleteButton.attr('href'),
        dataType: 'json',
        success() {
          const $manageModal = $deleteButton.closest('.att-manage-modal');
          $manageModal.on('hidden.bs.modal', () => {
            loadSingleTypeTab($deleteButton.attr('type_id'));
            swal.fire({ type: 'success', title: 'Attachment Deleted!' });
          });
          $manageModal.modal('hide');
        },
        error(response) {
          fireError(response);
        },
        complete() {
          enableButton($deleteButton);
        },
      });
      return false;
    });
  };

  const bindAttachmentEdit = () => {
    $mainBody.find('.att-manage-update').on('click', (e) => {
      const $element = $(e.currentTarget);
      const $form = $element.closest('.att-manage-modal').find('form.attachment-type-form');
      disableButton($element);
      $.ajax({
        method: $form.attr('method'),
        data: new FormData($form[0]),
        url: $form.attr('action'),
        contentType: false,
        processData: false,
        dataType: 'json',
        success() {
          const $manageModal = $element.closest('.att-manage-modal');
          $manageModal.on('hidden.bs.modal', () => {
            loadMainBody();
            swal.fire({ type: 'success', title: 'Attachment Updated!' });
          });
          $manageModal.modal('hide');
        },
        error(response) {
          fireError(response);
        },
        complete() {
          enableButton($element);
        },
      });
      return false;
    });
  };

  const bindPopupTrigger = () => {
    root.root.find('.show_image_attachment_popup').on('click', (e) => {
      $input = $(e.currentTarget);
      root.root.find('#image-attachments-popup').modal('show');
    });
  };

  const cmsPageEditSelectImage = () => {
    $mainBody.find('.select-img').on('click', (e) => {
      $input.val($(e.currentTarget).attr('image_url'));
      $input.closest('.image-input').find('.image-preview').find('img').attr('src', $(e.currentTarget).attr('image_url'));
      root.root.find('#image-attachments-popup').modal('hide');
    });
  };

  const ckEditorInsertUrl = () => {
    $mainBody.find('.select-img').on('click', (e) => {
      const url = $(e.currentTarget).attr('image_url');
      // returnFileUrl(url);
      const cKEditorFuncNum = (new URL(window.location)).searchParams.get('CKEditorFuncNum');
      window.opener.CKEDITOR.tools.callFunction(cKEditorFuncNum, url);
      window.close();
    });
  };

  // function getUrlParam(paramName) {
  //   const reParam = new RegExp(`(?:[\?&]|&)${paramName}=([^&]+)`, 'i');
  //   const match = window.location.search.match(reParam);
  //   return (match && match.length > 1) ? match[1] : null;
  // }
  // $.QueryString = (function (a) {
  //   if (a === '') return {};
  //   const b = {};
  //   for (let i = 0; i < a.length; ++i) {
  //     const p = a[i].split('=');
  //     if (p.length != 2) continue;
  //     b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
  //   }
  //   return b;
  // }(window.location.search.substr(1).split('&')));
  // // Simulate user action of selecting a file to be returned to CKEditor.
  // function returnFileUrl(fileUrl) {
  //   const funcNum = getUrlParam('CKEditorFuncNum');
  //   window.opener.CKEDITOR.tools.callFunction($.QueryString.CKEditorFuncNum, fileUrl);
  //   window.close();
  // }

  const bindPopupCloseCleanup = () => {
    root.root.find('#image-attachments-popup').on('hidden.bs.modal', (e) => {
      if (e.target.id === 'image-attachments-popup') $input = null;
    });
  };

  function fireError(error) {
    if (error.status === 500) {
      swal.fire({ type: 'error', text: 'Server Error, please try again later!' });
    } else {
      swal.fire({ type: 'error', text: error.responseJSON.error });
    }
  }

  // ======= event actions =========
  root.on('ATTACHMENTS_BODY_LOAD_FAILED', (error) => {
    console.log(error);
    fireError(error);
  });

  root.on('ATTACHMENTS_BODY_LOADED', () => {
    rememberOpenedType({ hashName: 'BEECHWOOD_ASCENSION_ATTACHMENT_TYPE', $root: $mainBody, className: 'type-link' });
    bindNewTypeCreateForm();
    bindRemoveTypeButton();
    bindNewImageButton();
    bindCloseManageModal();
    bindAttachmentDelete();
    bindAttachmentEdit();
    bindPopupCloseCleanup();
    if (root.root.find('.attachment-browser').length) {
      ckEditorInsertUrl();
    } else {
      cmsPageEditSelectImage();
    }
  });

  root.on('ATTACHMENT_TYPE_CREATED', () => {
    loadMainBody();
  });

  root.on('ATTACHMENT_TYPE_CREAT_ERROR', (error) => {
    const alertOptions = { type: 'error', title: 'Create New Attachment Type Failed!' };
    try {
      alertOptions.text = Object.values(error.responseJSON.error).join(', ');
    } catch (e) {
      fireError(e);
      // eslint-disable-next-line no-console
      console.error(e);
    }
    swal.fire(alertOptions);
  });

  root.on('ATTACHMENT_TYPE_REMOVED', () => {
    loadMainBody();
    swal.fire({ type: 'success', title: 'Attachment Type Removed' });
  });

  root.on('ATTACHMENT_TYPE_REMOVE_ERROR', (error) => {
    const alertOptions = { type: 'error', title: "Can't Remove Type!" };
    try {
      alertOptions.text = Object.values(error.responseJSON.error).join(', ');
    } catch (e) {
      fireError(e);
      // eslint-disable-next-line no-console
      console.error(e);
    }
    swal.fire(alertOptions);
  });

  root.on('ATTACHMENT_UPLOADED', ({ typeId }) => {
    loadSingleTypeTab(typeId);
  });

  root.on('ATTACHMENT_UPLOAD_ERROR', (error) => {
    fireError(error);
  });

  //
  root.on('FORM_RENERED', () => {
    $mainBody = root.root.find("[data-cms='image-attachments']");
    loadMainBody();
    bindPopupTrigger();
  });
  //

  // ===================== actions =====================
  // load attachment body first time
  loadMainBody();
  // =====================
}
