export default function rememberOpenedType ({ hashName, $root, className }) {
  const hashKey = `STORED_${hashName}`
  $root.find(`a.${className}[data-toggle]`).on("shown.bs.tab", e => {
    localStorage.setItem(hashKey, $(e.target).attr("href"))
  })
  const storedHash = localStorage.getItem(hashKey)
  if (storedHash) {
    if ($root.find(`a.${className}[href="${storedHash}"]`).length) {
      $root.find(`a.${className}[href="${storedHash}"]`).tab("show")
    } else {
      $root.find(`a.${className}[href]`).first().tab("show")
    }
  } else {
    $root.find(`a.${className}[href]`).first().tab("show")
  }
}