import BaseController from "controllers/pages/base";

class Controller extends BaseController {
  call() {
    super.call();

    console.log("Page Controller")

  }
};

export default Controller;
