/* eslint-disable no-use-before-define */
import BaseController from 'controllers/base';

import galleryCarousel from 'helpers/galleryCarousel';

class Controller extends BaseController {
  call() {
    super.call();

    galleryCarousel(this);
    pauseYoutubeWhenClose(this);
  }
}

function pauseYoutubeWhenClose(root) {
  root.root.find('.modal').on('hidden.bs.modal', (e) => {
    const $ifame = $(e.currentTarget).find('iframe');
    $ifame.attr('src', $ifame.attr('src'));
  });
}

export default Controller;
