import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import NewsTable from './newsTable';

export default function NewsListRoot() {
  const { categories } = gon;
  return (
    <Tabs variant="pills" className="my-5" defaultActiveKey={categories[0]} id="news-list">
      {categories.map((categorie) => (
        <Tab key={categorie} eventKey={categorie} title={_.startCase(_.toLower(categorie))}>
          <NewsTable category={categorie} />
        </Tab>
      ))}
    </Tabs>
  );
}
