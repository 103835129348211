import BaseController from 'controllers/base';

import disablePreviewClick from 'helpers/disablePreviewLinks';
import bindAttachments from 'widgets/cms/attachments';
import loadCkEditor from 'helpers/loadCkEditor';
import bindEditListButton from 'helpers/editListButton';
import bindSortableOrderLists from 'helpers/sortableList';
import bindCmsPageEditForm from 'helpers/cmsForms';

class Controller extends BaseController {
  call() {
    super.call();

    if (window.debugEvents) console.log('backend/cms/pages/edit controller js');

    disablePreviewClick(this);
    bindAttachments(this);
    loadCkEditor(this);
    bindEditListButton(this, '.edit-list-btn');
    bindSortableOrderLists(this, '.sortable-order-list');
    bindCmsPageEditForm(this);
  }
}

export default Controller;
