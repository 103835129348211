import { postFormData } from 'helpers/ajaxForms';
import { disableButton, enableButton } from 'helpers/buttons';

function formatMessage(json) {
  try {
    return Object.keys(json).map((key) => (json[key].length ? `${key}: ${json[key]}<br/>` : '')).filter((item) => item !== '').join(',');
  } catch (e) {
    return 'Errors';
  }
}

function bindUpdateButton(root) {
  const $editBody = root.root.find('#cms-edit-form');
  $editBody.find('button#update-page').on('click', (e) => {
    disableButton(e.currentTarget);
    postFormData.call(root, {
      form: 'form#pages-form',
      success(response) {
        root.emit('FORM_UPDATE_POSTED', response);
        root.emit('FORM_REQUESTED', response);
      },
      error(error) {
        if (error.status === 406) {
          root.emit('FORM_REQUESTED', error.responseJSON);
          swal.fire({
            type: 'error',
            title: 'Page Update Error!',
            text: formatMessage(error.responseJSON.errors),
          });
        } else if (error.status === 500) {
          swal.fire({
            type: 'error',
            title: 'Page Update Error!',
            text: 'Server Error, Please try again later.',
          });
        } else {
          swal.fire({
            type: 'error',
            title: 'Page Update Error!',
            text: `Unknown Error ${error.statusText}.`,
          });
        }
      },
      complete() {
        enableButton(e.currentTarget);
      },
    });
  });
}

function alertIfNotSave(root) {
  let formUnsaved = false;
  root.on('FORM_INPUT_UPDAED', () => {
    formUnsaved = true;
  });
  root.root.find('input').each((index, element) => {
    if (element.type === 'submit') {
      $(element).on('click', () => {
        formUnsaved = false;
      });
    } else {
      $(element).on('change', () => {
        formUnsaved = true;
      });
    }
  });
  // eslint-disable-next-line consistent-return
  function unloadPage() {
    if (formUnsaved) {
      return 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';
    }
  }
  window.onbeforeunload = unloadPage;
}

export default function bindCmsPageEditForm(root) {
  root.on('FORM_REQUESTED', (response) => {
    root.root.find('#cms-edit-form').html(response.html);
    root.emit('FORM_RENERED', response);
  });

  root.on('FORM_RENERED', () => {
    bindUpdateButton(root);
    alertIfNotSave(root);
  });

  root.on('FORM_UPDATE_POSTED', () => {
    root.root.find('iframe#page-preview').attr('src', (index, attr) => attr);
  });

  // Actions
  // first time load editBody
  root.emit('FORM_RENERED');
}
