import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import NewsListRoot from './components/newsListRoot';

import RootSaga from './sagas';
import configureStore from './store';

const startNewsApp = (rootElem) => {
  const store = configureStore();

  store.runSaga(RootSaga);

  ReactDOM.render(
    <Provider store={store}>
      <NewsListRoot />
    </Provider>,
    rootElem,
  );
};

export default startNewsApp;
