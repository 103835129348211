export function postFormData({form, success, error, complete}) {
  const _this = this
  let $cmsForm =  _this.root.find(form)
  $.ajax({
    method: $cmsForm.attr("method"),
    data: new FormData($cmsForm[0]),
    url: $cmsForm.attr("action"),
    contentType: false,
    processData: false,
    dataType: "json",
    success, 
    error, 
    complete
  })
}