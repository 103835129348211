import React from 'react';

export default function NewsItem({
  news: {
    id,
    attributes: {
      thumbnail,
      subtitle,
      bodyTabs,
      name,
      frontendUrl,
    },
  },
}) {
  return (
    <div className="col-md-4 post" key={id}>
      <a href={frontendUrl}>
        <img
          className="img-fluid"
          src={thumbnail}
          alt={name}
        />
      </a>
      <small>
        {subtitle}
      </small>
      <a href={frontendUrl}>
        <div className="title">{name}</div>
      </a>
      <p>{bodyTabs}</p>
    </div>
  );
}
