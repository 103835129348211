import BaseController from 'controllers/base';

import bindAttachments from 'widgets/cms/attachments';
import bindCmsPageEditForm from 'helpers/cmsForms';
import disablePreviewClick from 'helpers/disablePreviewLinks';
import loadCkEditor from 'helpers/loadCkEditor';

class Controller extends BaseController {
  call() {
    super.call();
    disablePreviewClick(this);
    loadCkEditor(this);
    bindAttachments(this);
    addDatePicker(this);
    bindCmsPageEditForm(this);
  }
}

function addDatePicker(root) {
  root.on('FORM_RENERED', () => {
    $("[data-toggle='datepicker']").datepicker({
      autoclose: true,
      immediateUpdates: true,
      format: 'dd MM yyyy',
      startDate: '01/01/2000',
      todayBtn: true,
      todayHighlight: true,
    });
  });
}

export default Controller;
