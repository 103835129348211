import BaseController from 'controllers/base';
import bindAttachments from 'widgets/cms/attachments';

class Controller extends BaseController {
  call() {
    super.call();
    bindAttachments(this);
  }
}

export default Controller;
