import axios from 'axios';
import normalize from 'json-api-normalizer';

// Get News Items
const getNewsList = ({ apiUrl, body }) => axios.post(apiUrl, body)
  .then(({ data }) => {
    const normalizedData = normalize(data.records);
    const defaultData = Object.values(normalizedData)[0] || {};
    // console.log(defaultData)
    return {
      response: {
        records: defaultData,
        meta: data.meta,
      },
    };
  })
  .catch((error) => ({ error: error.message }));

export default getNewsList;
