import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { CircleLoader } from 'react-spinners';
import { fetchDataStart } from '../actions';
import NewsItem from './newsItem';

const LoadMoreSpinner = (
  <div className="col-12 m-3 d-flex justify-content-center">
    <CircleLoader color="#ee1848" size={30} loading />
  </div>
);

const NoMoreDivider = (
  <div className="col-12 text-center">
    <div>No More Post</div>
    <hr className="mt-0" />
  </div>
);

export default function NewsTable({ category }) {
  const dispatch = useDispatch();
  const { allNews, byIds, totalCount } = useSelector((state) => state.newsList);
  const tabAllNews = allNews[category] || {};
  const tabByIds = byIds[category] || [];
  const tabTotalRecordCount = totalCount[category] || 0;
  const tabRecordsCount = tabByIds.length;
  const hasMore = Object.keys(byIds).includes(category) ? (tabTotalRecordCount > tabRecordsCount) : true;

  return (
    <InfiniteScroll
      className="row"
      dataLength={tabRecordsCount}
      next={() => dispatch(fetchDataStart({ category }))}
      hasMore={hasMore}
      endMessage={NoMoreDivider}
      loader={LoadMoreSpinner}
    >
      {tabByIds.map((id) => (Object.keys(tabAllNews).includes(String(id)) ? <NewsItem key={`news-${category}-${id}`} news={tabAllNews[id]} /> : ''))}
    </InfiniteScroll>
  );
}
