import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

export default function galleryCarousel(root) {
  root.root.find('#bwa-gallery').show();
  root.root.find('.gallery-carousel').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<a class="carousel-control-prev" data-slide="prev" href="#carouselExampleIndicators" role="button"><span aria-hidden="true" class="carousel-control-prev-icon"></span><span class="sr-only">Previous</span></a>',
    nextArrow: '<a class="carousel-control-next" data-slide="next" href="#carouselExampleIndicators" role="button"><span aria-hidden="true" class="carousel-control-next-icon"></span><span class="sr-only">Next</span></a>',
    asNavFor: '.gallery-nav',
  });
  root.root.find('.gallery-nav').slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    asNavFor: '.gallery-carousel',
    // dots: true,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
  });
}
