import { combineReducers } from 'redux';

import allNews from './allNews';
import byIds from './byIds';
import totalCount from './totalCount';
import curPage from './curPage';

export default combineReducers({
  allNews,
  byIds,
  totalCount,
  curPage,
});
