import BaseController from 'controllers/pages/base';

import startNewsApp from 'apps/BlogList';

class Controller extends BaseController {
  call() {
    super.call();

    console.log('Blog Controller');
    startNewsApp(
      document.getElementById('blogs-list'),
      {},
    );
  }
}

export default Controller;
