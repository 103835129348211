import $ from 'jquery';

import { disableButton, enableButton } from 'helpers/buttons';
import { postFormData } from 'helpers/ajaxForms';

export function triggerUrl(root, url, button) {
  disableButton(button);
  $.ajax({
    method: 'get',
    url,
    dataType: 'json',
    success(response) {
      root.emit('EDIT_ATTACHMENT_SUCCEED', response);
      root.emit('FORM_REQUESTED', response);
    },
    error(error) {
      root.emit('ADD_ATTACHMENT_FAILED', error);
    },
    complete() {
      enableButton(button);
    },
  });
}

export default function bindEditListButton(root, button) {
  root.on('FORM_RENERED', () => {
    root.root.find(button).on('click', (e) => {
      const buttonClicked = e.currentTarget;
      const targetUrl = $(e.currentTarget).attr('url-target');
      const $updateBtn = root.root.find('#update-page');
      disableButton($updateBtn);
      disableButton(buttonClicked);
      postFormData.call(root, {
        form: 'form#pages-form',
        success() {
          triggerUrl(root, targetUrl, buttonClicked);
        },
        error(error) {
          root.emit('FORM_UPDATE_POST_FAILED', error);
        },
        complete() {
          enableButton($updateBtn);
          disableButton(buttonClicked);
        },
      });
    });
  });
}
