import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';

export default function configureStore(initialState) {
  const SagaMiddleware = createSagaMiddleware();

  return {
    ...createStore(
      createRootReducer(),
      initialState,
      composeWithDevTools(applyMiddleware(SagaMiddleware)),
    ),
    runSaga: SagaMiddleware.run,
  };
}
