/* eslint-disable no-use-before-define */
import BaseController from 'controllers/base';
import $ from 'jquery';
// eslint-disable-next-line import/extensions
import Sortable from 'sortablejs/modular/sortable.core.esm.js';

import bindAttachments from 'widgets/cms/attachments';

class Controller extends BaseController {
  call() {
    super.call();
    sectorSortable(this);
    disableLinks(this);
    bindAttachments(this);
  }
}

function disableLinks(root) {
  root.root.find('.menu-preview a').on('click', (e) => {
    e.preventDefault();
  });
}

function sectorSortable(root) {
  root.root.find('.menu-list').each((idx, elem) => {
    // eslint-disable-next-line no-unused-vars
    const sortlist = new Sortable(elem, {
      handle: '.drag-handle',
      animation: 150,
      ghostClass: 'temp-item',
      onEnd(evt) {
        updateSectorListValues(evt);
      },
    });
  });
}

function updateSectorListValues(evt) {
  const $elem = $(evt.target);
  $elem.find('.menu-item').each((itemIdx, itemElem) => {
    $(itemElem).find('input.menu-order-input').val(itemIdx);
  });
}

export default Controller;
