import BaseController from 'controllers/base';

import bindAttachments from 'widgets/cms/attachments';
import loadCkEditor from 'helpers/loadCkEditor';
import bindCmsPageEditForm from 'helpers/cmsForms';
import bindSortableOrderLists from 'helpers/sortableList';
import bindEditListButton from 'helpers/editListButton';

class Controller extends BaseController {
  call() {
    super.call();
    loadCkEditor(this);
    bindAttachments(this);
    bindEditListButton(this, '.edit-list-btn');
    bindSortableOrderLists(this, '.sortable-order-list');
    bindCmsPageEditForm(this);
  }
}

export default Controller;
