import BaseController from 'controllers/pages/base';

import cmsFormControl from "helpers/formSubmissions"

class Controller extends BaseController {
  call() {
    super.call();

    console.log('Page Controller');
    cmsFormControl(this)
  }
}

export default Controller;
